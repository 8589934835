import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { FormattedMessage } from "react-intl";
import { Button } from "./Core";
import { device } from "../utils";
import Logo from "./Logo";

const SiteHeader = styled.header`
  padding: 10px 0 10px 0;
  position: absolute !important;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  @media ${device.md} {
    position: fixed !important;
    transition: 0.4s;
    &.scrolling {
      transform: translateY(-100%);
      transition: 0.4s;
    }
    &.reveal-header {
      transform: translateY(0%);
      box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
      z-index: 9999;
      background: ${({ dark, theme }) =>
        dark ? theme.colors.dark : theme.colors.light};
    }
  }
`;

const StyledButton = styled(Button)`
  font-size: 16px !important;
  min-width: 0 !important;
  height: 45px !important;
  padding: 0.4rem 0.75rem !important;
  @media ${device.sm} {
    min-width: 141px !important;
  }
`;

const Menu = styled.ul`
  display: flex;
  justify-content: flex-end;
  .dropdown-toggle {
    cursor: pointer;
  }
  > li {
    > .nav-link {
        color: ${({ dark, theme }) =>
          dark ? theme.colors.lightShade : theme.colors.darkShade}!important;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        display: flex;
        align-items: center;
        height: 45px;
    }
  }
  .nav-item.dropdown {
      position: relative;
      z-index: 99;
    &:hover {
      > .menu-dropdown {
          top: 100%;
          opacity: 1;
          pointer-events: visible;
      }
    }
`;

const MenuDropdown = styled.ul`
  list-style: none;
  top: 90%;
  position: absolute;
  box-shadow: 0 52px 54px rgba(65, 62, 101, 0.3);
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  background-color: #ffffff;
  padding: 15px 0px;
  z-index: 99;
  opacity: 0;
  transition: opacity 0.4s, top 0.4s;
  pointer-events: none;
  right: 0%;
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #ffffff;

  .drop-menu-item {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: -0.5px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    color: ${({ theme }) => theme.colors.dark};
    transition: all 0.3s ease-out;
    position: relative;
    &.dropdown-toggle::after {
      display: inline-block;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.325em solid;
      border-right: 0.325em solid transparent;
      border-bottom: 0;
      border-left: 0.325em solid transparent;
      position: relative;
      top: 1px;
      margin-left: auto;
      transform: rotate(-90deg);
      transition: 0.4s;
    }

    &:hover {
      color: ${({ theme }) => theme.colors.secondary} !important;
      text-decoration: none;
      &::after {
        transform: rotate(0deg);
      }
    }
    &.dropdown {
      position: relative;

      &:hover {
        > .menu-dropdown {
          @media ${device.md} {
            top: 10px;
            opacity: 1;
            pointer-events: visible;
            transform: translateX(100%);
          }
        }
      }
      > .menu-dropdown {
        border-top-color: ${({ theme }) => theme.colors.primary};
        top: 10px;
        left: 0%;
        opacity: 0;
        transform: translateX(-110%);
        transition: 0.4s;
        pointer-events: none;
        .drop-menu-item {
          padding-left: 30px;
          padding-right: 30px;
        }
      }
    }
  }
  &.dropdown-right {
    left: auto;
    right: 0;
  }
`;

class HeaderContainer extends React.Component {
  state = {
    isMobile: false,
  };

  throttledHandleWindowResize = () => {
    this.setState({ isMobile: window.innerWidth < 992 });
  };

  componentDidMount() {
    this.throttledHandleWindowResize();
    window.addEventListener("resize", this.throttledHandleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.throttledHandleWindowResize);
  }

  render() {
    const { isMobile } = this.state;
    return <Header {...this.props} isMobile={isMobile} />;
  }
}

const Header = ({
  isDark = false,
  changeOnScroll = false,
  homeLink,
  isMobile,
  url,
  langs,
  langNames,
  langNamesShort,
  siteUrl,
  affiliate,
}) => {
  const [showScrolling, setShowScrolling] = useState(false);
  const [showReveal, setShowReveal] = useState(false);

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < 0) {
      setShowScrolling(true);
    } else {
      setShowScrolling(false);
    }
    if (currPos.y < -300) {
      setShowReveal(true);
    } else {
      setShowReveal(false);
    }
  });

  return (
    <>
      <SiteHeader
        className={`sticky-header ${showScrolling ? "scrolling" : ""} ${
          showReveal ? "reveal-header" : ""
        }`}
        dark={isDark ? 1 : 0}
      >
        <Container
          fluid
          data-aos="fade-top"
          data-aos-duration="750"
          data-aos-delay="500"
          data-aos-once="true"
        >
          <nav
            className="navbar site-navbar offcanvas-active navbar-expand-md navbar-light"
            style={{ padding: "10px 5px" }}
          >
            {/* <!-- Brand Logo--> */}
            <div className="brand-logo">
              <Logo
                white={isDark}
                homeLink={homeLink}
                changeOnScroll={changeOnScroll && !showReveal && !isMobile}
              />
            </div>

            <Menu
              className="navbar-nav d-flex ml-auto mr-3"
              dark={isDark ? 1 : 0}
            >
              <li className="nav-item dropdown">
                <a
                  className="nav-link"
                  role="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  href="/#"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fas fa-globe" />
                  {langs.map(
                    (lang) =>
                      lang.selected &&
                      !isMobile && (
                        <span style={{ marginLeft: 5 }}>
                          {langNames[lang.number]}
                        </span>
                      )
                  )}
                </a>
                <MenuDropdown className="menu-dropdown" dark={isDark ? 1 : 0}>
                  {langs.map(
                    (lang) =>
                      !lang.selected && (
                        <Link
                          to={lang.link}
                          key={lang.langKey}
                          onClick={() => {
                            var d = new Date();
                            d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
                            var expires = "expires=" + d.toUTCString();
                            document.cookie =
                              "lang=" +
                              lang.langKey +
                              ";" +
                              expires +
                              ";path=/;SameSite=Strict";
                          }}
                        >
                          <li className="drop-menu-item dropdown-right">
                            {langNames[lang.number]}
                          </li>
                        </Link>
                      )
                  )}
                </MenuDropdown>
              </li>
            </Menu>

            <div className="header-btns">
              <a
                href={
                  siteUrl + "/manage" + (affiliate ? "/?ref=" + affiliate : "")
                }
                target="_blank"
                rel="noopener noreferrer"
                id="LoginButton"
              >
                <StyledButton size="sm">
                  <i
                    className="fas fa-user-circle d-none d-md-block"
                    style={{ marginRight: 10 }}
                  />
                  <FormattedMessage id="login" defaultMessage="Login" />
                </StyledButton>
              </a>
            </div>
          </nav>
        </Container>
      </SiteHeader>
    </>
  );
};

export default HeaderContainer;
